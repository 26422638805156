<template>
   <div id="input-dialog">
        <div class="content">
            <h3 class="title" v-text="title"></h3>
            <div class="content-box">
                <input type="text" class="value" v-model="value" placeholder="請輸入">
            </div>
            <div class="operate">
                <div class="btn btn-cancel" @click="onClose(false)" v-text="cancelText || '取消'"></div>
                <div class="btn btn-ok" @click="onClose(true)" v-text="okText || '確認'"></div>
            </div>
        </div>
   </div>
</template>
<script>
export default {
    name: 'InputDialog',
    props: {
        onInputDialogClose: Function,
        title: String,
        inputValue: String,
        okText: String,
        cancelText: String,
    },
    data() {
        return {
            style: '',
            value: '',
        }
    },
    created() {
        this.value = this.inputValue || ''
        this.style = document.body.getAttribute('style') || ''
        document.body.setAttribute('style', this.style + 'overflow-x: hidden;')
    },
    methods: {
        onClose: function(flag){
            this.onInputDialogClose && this.onInputDialogClose(flag, this.value)
            document.body.setAttribute('style', this.style) 
        },
    }
};
</script>
<style scoped>
    #input-dialog {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        background-color: rgba(10, 10, 10, 0.5);
        user-select: none;
    }

    #input-dialog .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        height: 150px;
        border-radius: 12px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }

    #input-dialog .content .title {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f3f3f3;
        box-sizing: border-box;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
    }

    #input-dialog .content .message {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #input-dialog .content .operate {
        height: 40px;
        line-height: 40px;
        border-top: 1px solid #f3f3f3;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
    }

    #input-dialog .content .operate .btn {
        flex: 1;
        text-align: center;
        cursor: pointer;
    }

    #input-dialog .content .operate .btn:active {
        background-color: #efeeee;
        box-sizing: border-box;
    }
    #input-dialog .content .operate .btn.btn-cancel {
        border-bottom-left-radius: 12px;
    } 
    #input-dialog .content .operate .btn.btn-ok {
        border-left: 1px solid #f3f3f3;
        box-sizing: border-box;
        border-bottom-right-radius: 12px;
    }

    #input-dialog .content .content-box {
        flex: 1;
        display: flex;
        flex-direction: row;
        padding: 0 12px;
        align-items: center;
    }
    #input-dialog .content .value {
        height: 40px;
        line-height: 40px;
        flex: 1;
        border: 1px solid #f3f3f3;
        border-radius: 4px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0px 12px;
    }

    @media only screen and (max-width: 1050px) {
        #input-dialog {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 9999;
            background-color: rgba(10, 10, 10, 0.5);
            user-select: none;
            font-size: 32px;
        }
    
        #input-dialog .content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80vw;
            height: 50vw;
            border-radius: 12px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
        }
    
        #input-dialog .content .title {
            height: 80px;
            line-height: 80px;
            border-bottom: 1px solid #f3f3f3;
            box-sizing: border-box;
            text-align: center;
            font-weight: 500;
            font-size: 36px;
        }
    
        #input-dialog .content .message {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #input-dialog .content .operate {
            height: 80px;
            line-height: 80px;
            border-top: 1px solid #f3f3f3;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
        }
    
        #input-dialog .content .operate .btn {
            flex: 1;
            text-align: center;
            cursor: pointer;
        }
    
        #input-dialog .content .operate .btn:active {
            background-color: #efeeee;
            box-sizing: border-box;
        }
        #input-dialog .content .operate .btn.btn-cancel {
            border-bottom-left-radius: 12px;
        } 
        #input-dialog .content .operate .btn.btn-ok {
            border-left: 1px solid #f3f3f3;
            box-sizing: border-box;
            border-bottom-right-radius: 12px;
        }
    
        #input-dialog .content .content-box {
            flex: 1;
            display: flex;
            flex-direction: row;
            padding: 0 12px;
            align-items: center;
        }
        #input-dialog .content .value {
            height: 80px;
            line-height: 80px;
            flex: 1;
            border: 1px solid #f3f3f3;
            border-radius: 4px;
            background-color: #fff;
            box-sizing: border-box;
            padding: 0px 12px;
        }
    }
</style>